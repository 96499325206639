'use client';
import { useSidebarDraw } from "@/contexts/SidebarDrawProvider";
import { ReactNode } from "react"

export const HtmlBody = ({ children }: {
  children: ReactNode
}) => {
  const { draw } = useSidebarDraw()
  return <body className={'group/body data-[draw=open]:overflow-hidden md:data-[draw=open]:overflow-y-auto'} data-draw={draw ? 'open' : 'closed'}>
    {children}
  </body>
}