import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useFeatureFlags } from "./FeatureFlagsProvider";
import { aiCopilotPreviewMessages, countAiCopilotPreviewMessages, refreshAiCopilotPreviewMessages } from "@/redux/features/counter";
import { useChat } from "./ChatProvider";
import { ack, aiCopilotPreview } from "@/redux/features/consent";
import { useUserInformation } from "./UserProvider";

type CopilotPreview = {
  enabled: boolean
  limit: number
  messageCount: number
  acknowledge: () => void
  acknowledged: boolean
  submitMessage: (event?: React.FormEvent<HTMLFormElement>) => void
  oldestMessageDate: Date
}

const CopilotPreviewContext = createContext<CopilotPreview>(null!);

export const useCopilotPreview = () => useContext(CopilotPreviewContext);

export const CopilotPreviewProvider = ({children}: {
  children: React.ReactNode
}) => {
  const dispatch = useAppDispatch()
  const { flags: { FLAG_COPILOT_PREVIEW_LIMIT, FLAG_COPILOT_PREVIEW }} = useFeatureFlags()
  const { isLoggedIn } = useUserInformation()
  const expiryTimestamp = Date.now() - (1000 * 60 * 60 * 24);
  const {messages, hasMessage, setStatus, threadId, input, append, createMessage} = useChat()
  const previewMessages = useAppSelector(aiCopilotPreviewMessages)
    .filter((timestamp) => timestamp > expiryTimestamp)
    .map((message) => new Date(message))

  // We want to recall the basePreviewMessageCount when the component is mounted only.
  const [basePreviewMessageCount] = useState(previewMessages.length)
  const aiMessageCount = basePreviewMessageCount + messages.filter((message) => message.role === 'assistant').length
  const limitReached = !isLoggedIn && (aiMessageCount >= FLAG_COPILOT_PREVIEW_LIMIT)
  
  const updateMessageCount = useCallback(() => {
    for (let i = basePreviewMessageCount; i < aiMessageCount; i++) {
      dispatch(countAiCopilotPreviewMessages(new Date()))
    }
  }, [basePreviewMessageCount, aiMessageCount, dispatch, countAiCopilotPreviewMessages])

  useEffect(() => {
    updateMessageCount();
    if (limitReached && !hasMessage('aiCopilotPreview')) {
      setStatus({
        id: 'aiCopilotPreview',
        role: 'status',
        content: <>
          <p>
          Thanks for trying Acquia Copilot. Interactions are currently limited to {FLAG_COPILOT_PREVIEW_LIMIT} messages per day.
          </p>
          <p>We would appreciate your feedback about your experience. Take this <a className="underline text-blue-600" href="https://www.surveymonkey.com/r/B6L2W55" target="_blank">1-minute survey</a>.</p>
        </>
      })
    }
    dispatch(refreshAiCopilotPreviewMessages(new Date(expiryTimestamp))) 
  }, [limitReached])

  const submitMessage = async (
    event?: React.FormEvent<HTMLFormElement>,
  ) => {
    event?.preventDefault?.();

    if (input === '') {
      return;
    }
    append(createMessage(input), !FLAG_COPILOT_PREVIEW || !limitReached);
  };

  return <CopilotPreviewContext.Provider value={{
    enabled: !isLoggedIn && FLAG_COPILOT_PREVIEW,
    limit: FLAG_COPILOT_PREVIEW_LIMIT,
    messageCount: aiMessageCount,
    acknowledged: useAppSelector(aiCopilotPreview),
    acknowledge: () => dispatch(ack('aiCopilotPreview')),
    submitMessage,
    oldestMessageDate: previewMessages.reduce((oldest, current) => current < oldest ? current : oldest, new Date())
  }}>
    {children}
  </CopilotPreviewContext.Provider>
}