'use client'
import { createContext, ReactNode, useContext } from "react";

export type FeatureFlagsContext = {
  flags: {
    FLAG_AI_CHAT_ENABLED: boolean
    FLAG_USER_LOGIN: boolean
    FLAG_COPILOT_PREVIEW: boolean
    FLAG_COPILOT_PREVIEW_LIMIT: number
    STAGE: 'production' | 'preprod' | 'stage' | string
  }
}

const FeatureFlagsContext = createContext<FeatureFlagsContext>({
  flags: {
    FLAG_AI_CHAT_ENABLED: true,
    FLAG_USER_LOGIN: false,
    FLAG_COPILOT_PREVIEW: true,
    FLAG_COPILOT_PREVIEW_LIMIT: 10,
    STAGE: 'production'
  }
})

export const useFeatureFlags = () => useContext(FeatureFlagsContext);

export const FeatureFlagsProvider = (props: FeatureFlagsContext & {
  children: ReactNode
}) => {
  return <FeatureFlagsContext.Provider value={props}>
    {props.children}
  </FeatureFlagsContext.Provider>
}