'use client'
import { useAssistant } from "ai/react"
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { CopilotPreviewProvider } from "./CopilotPreviewProvider";
import { ChatMessage, ThreadChatMessage } from "@/lib/copilot/types";
import { generateId } from "ai";
import { Thread } from "openai/resources/beta/threads/threads.mjs";

type ChatTools = Omit<ReturnType<typeof useAssistant>, 'messages' | 'append'> & {
  isLoading: boolean
  messages: ThreadChatMessage[],
  append: (message: ChatMessage<'user', string>, sendToAssistant?: boolean) => void,
  hasMessage: (id: string) => boolean,
  createMessage: (content: string) => ChatMessage<'user', string>,
  setStatus: (message: ChatMessage<'status'>) => void,
  reload: () => void
}

const ChatContext = createContext<ChatTools>(null!);

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({children}: {
  children: React.ReactNode
}) => {

  const [messages, setMessages] = useState<ThreadChatMessage[]>([])

  function hasMessage(id: string) {
    return messages.some(message => message.id == id)
  }

  function addMessage(message: ThreadChatMessage) {
    if (!hasMessage(message.id)) {
      setMessages([...messages, message])
    }
    else {
      // Update existing message.
      messages.find(m => m.id == message.id)!.content = message.content
      setMessages(messages)
    }
  }

  function createMessage(content: string): ChatMessage<'user', string> {
    return {
      id: generateId(),
      content,
      role: 'user'
    }
  }

  const chat = useAssistant({
    api: '/api/agent'
  });

  const syncMessages = useCallback((newMessages: ChatMessage<'user'|'assistant'|'system'|'data'>[]) => {
    setMessages((messages) => {
      newMessages.forEach(message => {
        if (message.role != 'user' && !hasMessage(message.id)) {
          messages.push(message)
        }
      })
      return messages
    })
  }, [])

  const newMessages = chat.messages.filter(message => !hasMessage(message.id) && message.role != 'user')
  if (newMessages.length > 0) {
    syncMessages(newMessages)
  }

  function append(message: ChatMessage<'user', string>, sendToAssistant: boolean = true) {
    addMessage(message)
    if (sendToAssistant) {
      chat.append(message)
    }
  }

  const submitMessage = async (
    event?: React.FormEvent<HTMLFormElement>,
  ) => {
    event?.preventDefault?.();

    if (chat.input === '') {
      return;
    }
    append(createMessage(chat.input));
  };


  const reload = () => {
    let last_message
    const messages = chat.messages
    do {
      last_message = messages.pop()
    }
    while (last_message && last_message.role != 'user');

    if (last_message) {
      chat.setMessages(messages)
      chat.append(last_message);
    }
  }
  return <ChatContext.Provider value={{
    ...chat, 
    reload,
    append,
    submitMessage,
    createMessage,
    messages,
    hasMessage,
    setStatus: (message: ChatMessage<'status'>) => addMessage(message),
    isLoading: chat.status == 'in_progress', 
  }}>
    <CopilotPreviewProvider>
      {children}
    </CopilotPreviewProvider>
  </ChatContext.Provider>
}