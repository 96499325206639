import { createAppSlice } from "../createAppSlice"; 
import { type PayloadAction } from "@reduxjs/toolkit";
import { load, save } from "../utils";

export interface CounterState {
  aiCopilotPreviewMessages: Array<number>,
}

const initialState: CounterState = {
  aiCopilotPreviewMessages: [],
};

export const counter = createAppSlice({
  name: 'counter',
  initialState,
  reducers: (create) => ({
    initialize: create.reducer(
      (state) => load<CounterState>('counter')
    ),
    countAiCopilotPreviewMessages: create.reducer(
      (state, action: PayloadAction<number>) => {
        state.aiCopilotPreviewMessages.push(action.payload)
        save<CounterState>('counter', state)
      }
    ),
    refreshAiCopilotPreviewMessages: create.reducer(
      (state, action: PayloadAction<number>) => {
        // Remove messages older than 24 hours
        state.aiCopilotPreviewMessages = state.aiCopilotPreviewMessages.filter((date) => date > action.payload)
        save<CounterState>('counter', state)
      }
    ),
  }),
  selectors: {
    aiCopilotPreviewMessages: (state) => state.aiCopilotPreviewMessages,
  },
})

// export const {refreshAiCopilotPreviewMessages} = counter.actions
export const {aiCopilotPreviewMessages} = counter.selectors

export function countAiCopilotPreviewMessages(date: Date) {
  return counter.actions.countAiCopilotPreviewMessages(date.getTime())
}

export function refreshAiCopilotPreviewMessages(date: Date) {
  return counter.actions.refreshAiCopilotPreviewMessages(date.getTime())
}