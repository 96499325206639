"use client";
import { useQueryStates, Values } from "nuqs";
import { SidebarDrawContext, useSidebarDraw } from "./SidebarDrawProvider";
import { searchParamsSearch } from "@/lib/searchParams";
import {
  createContext,
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { sendGTMEvent } from "@next/third-parties/google";
import { usePathname } from "next/navigation";

type Filters = {
  products?: string[];
};

type SearchFunction = <T extends string | SearchInterface>(
  query: T,
  filters?: T extends string ? Filters : undefined,
  referer?: T extends string ? string : undefined
) => void;

type SearchContext = Required<SidebarDrawContext> & {
  search: SearchFunction;
  current?: SearchInterface;
  clear: () => void;
  input: RefObject<HTMLInputElement>;
};

const SearchContext = createContext<SearchContext>(null!);

export const useSearch = () => useContext(SearchContext);

export const SearchProvider = ({ children }: { children: ReactNode }) => {
  const draw = useSidebarDraw();
  const { isOpen, openDraw, registerOnCloseCallback } = draw;
  const [current, setCurrent] = useState<SearchInterface>();
  const [{ searchQuery, searchFiltersProducts, searchReferrer }, setSearchParams] =
    useQueryStates(searchParamsSearch);
  const input = useRef<HTMLInputElement>(null);
  const pathname = usePathname()

  // When the page is loaded and a searchQuery param is present, set the draw to open.
  const filterJson = JSON.stringify({ products: searchFiltersProducts ?? [] });
  useEffect(() => {
    const filter = JSON.parse(filterJson) as SearchInterface["filters"];
    if ((searchQuery || filter?.products?.length) && !isOpen) {
      setCurrent(
        Search.fromQueryParams({
          searchQuery,
          searchFiltersProducts: filter?.products ?? null,
          searchReferrer,
        })
      );
      openDraw("search");
    }
  }, [searchQuery, filterJson, isOpen]);

  useEffect(() => {
    registerOnCloseCallback("search", () => {
      setSearchParams({
        searchQuery: null,
        searchFiltersProducts: null,
        searchReferrer: null,
      });
      setCurrent(undefined);
    });
  }, []);

  const params = JSON.stringify(current?.urlSearchParams);

  // Ensure the query state reflects the current search.
  useEffect(() => {
    if (params) {
      setSearchParams(JSON.parse(params));
    }
  }, [params, pathname]);

  return (
    <SearchContext.Provider
      value={{
        ...draw,
        search: <T extends string | SearchInterface>(
          query: T,
          filters?: T extends string ? Filters : undefined,
          referer?: T extends string ? string : undefined
        ) => {
          setCurrent(
            typeof query == "string"
              ? new Search(query, filters, referer)
              : (query as SearchInterface)
          );
          openDraw("search");
        },
        current,
        input,
        clear: () => {
          setSearchParams({
            searchQuery: null,
            searchFiltersProducts: null,
            searchReferrer: null,
          });
          setCurrent(undefined);
        },
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export interface SearchInterface {
  query: string;
  filters?: Filters;
  referer?: string;
  previous?: SearchInterface;
  urlSearchParams: {
    searchQuery: string;
    searchFilterProducts?: string[];
    searchReferrer?: string;
  };
  addProductFilter(name: string): SearchInterface;
  augment(
    searchPhrase: string,
    filters?: Filters,
    referer?: string
  ): SearchInterface;
  equals(search: SearchInterface): boolean;
}

class Search implements SearchInterface {
  public readonly query: string;
  public readonly filters?: Filters;
  public readonly referer?: string;
  public readonly previous?: Search;

  constructor(
    query: string,
    filters?: Filters,
    referer?: string,
    previous?: Search
  ) {
    this.query = query;
    this.filters = filters;
    this.referer = referer;
    this.previous = previous;

    if (query.length) {
      sendGTMEvent({
        event: "search",
        value: query,
      });
    }
  }

  augment(searchPhrase: string, filters?: Filters, referer?: string): Search {
    return new Search(searchPhrase, filters, referer, this);
  }

  public get urlSearchParams() {
    return {
      searchQuery: this.query,
      searchFiltersProducts: this.filters?.products ?? [],
      searchReferrer: this.referer,
    };
  }

  public addProductFilter(name: string): SearchInterface {
    return this.augment(
      this.query,
      {
        products: [...(this.filters?.products ?? []), name],
      },
      this.referer
    );
  }

  public equals(search: SearchInterface): boolean {
    if (search.query != this.query) {
      return false;
    }
    if (search.filters === this.filters) {
      return true;
    }
    if (
      Array.isArray(search.filters?.products) &&
      Array.isArray(this.filters?.products)
    ) {
      return (
        search.filters?.products.join(",") == this.filters?.products.join(",")
      );
    }
    return false;
  }

  static fromQueryParams(params: Values<typeof searchParamsSearch>): Search {
    return new Search(
      params.searchQuery ?? "",
      {
        products: params.searchFiltersProducts ?? [],
      },
      params.searchReferrer ?? "",
    );
  }

  static getDefaultParams() {
    return {
      searchQuery: null,
      searchFiltersProducts: [],
      searchReferrer: null,
    };
  }
}
