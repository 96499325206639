import { useUserInformation } from "@/contexts/UserProvider";
import LoadingSvg from "@/assets/loading.svg";
import classNames from "classnames";
import { signIn } from "next-auth/react";
import { useState, Fragment } from "react";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import LogOutIcon from "@/public/assets/feather/log-out.svg";
import { usePathname } from "next/navigation";
import { useFeatureFlags } from "@/contexts/FeatureFlagsProvider";
import { Menu, Transition } from "@headlessui/react";
import Link from "next/link";

export const LoginButton = () => {
  const { isLoggedIn } = useUserInformation();
  const {
    flags: { STAGE: stage, FLAG_USER_LOGIN },
  } = useFeatureFlags();
  const [clicked, setClicked] = useState(false);
  const path = usePathname();

  if (!FLAG_USER_LOGIN && !isLoggedIn) {
    return <></>
  }

  const btnClassName =
    "hover:text-white border w-fit whitespace-nowrap border-white rounded-full p-3 transition-all duration-300";

  if (isLoggedIn) {
    return (
      <a
        className={classNames(btnClassName, "hover:bg-pink-800 text-pink-800")}
        href={`/api/auth/signout?callbackUrl=${path}`}
        title="Sign out"
      >
        <span className="sr-only">Logout</span>
        <LogOutIcon className="w-[24px]" />
      </a>
    );
  }

  function onClick(login = false) {
    setClicked(true);
    if (login) {
      signIn("okta");
    }
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className={({ open }) => classNames(
          "flex items-center p-3 rounded-full truncate",
          "hover:bg-navy-600 hover:text-white",
          "focus:border-navy-600 focus-visible:ring-2 focus-visible:ring-white/75",
          open ? 'bg-navy-600 text-white' : 'border-transparent'
        )}
          title="Sign in Options">
          {clicked ? <LoadingSvg className="w-[24px]" title="Please wait" /> : <UserCircleIcon className="w-[24px]" />}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={classNames(
          "absolute left-0 origin-top-right shadow-xl",
          "p-1 w-max rounded-b-md bg-white z-30"
        )}>
          <div className="px-1 py-1 ">
            <Menu.Item>
              {({ active }) => (
                <button
                  role="button" 
                  className={`${active ? 'bg-gray-400' : ''
                    } hover:bg-gray-400 group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={() => onClick(true)}
                  title="Sign in"
                >
                  Sign in
                </button>
              )}

            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
