'use client'
import { LinkType, PreviewBarContextProps, usePreviewBar } from "@/contexts/PreviewBarProvider"
import { MinusIcon, PlusIcon, XMarkIcon } from "@heroicons/react/20/solid"
import { redirect, usePathname } from "next/navigation"
import { ReactNode, useEffect, useState } from "react"
import { Body } from "../../wysiwyg/Body"
import { DrupalEntityWithJiraReference } from "@/types"
import { DocumentIcon, PencilSquareIcon, WrenchScrewdriverIcon } from "@heroicons/react/24/outline"
import classNames from "classnames"
import { useContentFromRoute } from "@/contexts/ContentRouteProvider"
import { LoadingCover } from "@/components/block/LoadingCover.client"

type PreviewState = NonNullable<PreviewBarContextProps["preview"]["state"]>

const state_message_map:Record<PreviewState, string> = {
  published: 'Published',
  draft: 'Draft',
  archived: 'Archived',
  code: 'In code',
  loading: 'Loading...'
}

export const PreviewBar = ({}:{}) => {
  const pathname = usePathname()
  const {preview, reset, enabled} = usePreviewBar()
  const [inIframe, setInIframe] = useState(true)
  const [path, setPath] = useState(pathname)

  useEffect(() =>  {
    setInIframe(typeof window !== "undefined" && window.self != window.top)
  }, [])

  useEffect(() => {
    setPath(pathname)
  }, [pathname])

  useEffect(() => {
    if (path != pathname) {
      reset()
    }
  }, [path, pathname, reset])


  const highlight = classNames(
    preview.mode == 'current' ? 'bg-teal-400' : (
      preview.mode == 'diff' ? 'bg-gray-400' : (
        preview.mode == 'revision' ? 'bg-yellow-400' : 'bg-gray-400')))

  const subtitle = preview.mode == 'diff' ? 'Showing diff' : state_message_map[preview.state as PreviewState]

  if (!enabled) {
    return <></>
  }

  return <div className={`sticky top-0 z-10 ${highlight} drop-shadow`}>
       {!inIframe && 
        <a title="Close preview mode" href={'/api/end-preview?slug=' + pathname} 
         className="transition absolute right-0 m-2 hover:bg-white/50 rounded py-2 px-4 flex text-sm">
          <XMarkIcon className='w-4 overflow-visible' />
        </a>} 
        <div className="container mx-auto p-3 flex flex-row gap-3 items-center">
          <div>
            <h2 className="font-display whitespace-nowrap">Preview mode</h2>
            <div className="whitespace-nowrap">{subtitle}</div>
          </div>
          { preview.modes.length > 1 && <ViewModes />}
          
          {/* Only display when preview is embedded inside an iframe. */}
          {!inIframe && preview.links && preview.links.length > 0 && <ViewLinks />}
          <div className="duration-300 transition-all overflow-hidden max-h-12">
            <Body value={preview.resource?.revision_log ?? ''} />
          </div>
      </div>
    </div>
}

const ViewModes = ({children}: {
  children?: ReactNode
}) => {
  const { set, preview: {mode, modes} } = usePreviewBar()

  return <div className="flex gap-1">
    {children}
    {modes.includes('current') && (
      <button 
        role="button"
        onClick={() => set.mode('current')}
        title="View the current published version."
        className={classNames(mode == 'current' ? 'bg-teal-600 text-white' : 'bg-gray-600 hover:bg-teal-600 hover:text-white', "p-2 rounded-lg")}>
          <DocumentIcon className="w-5" />
      </button>
    )}
    {modes.includes('diff') && (
      <button 
        role="button"
        onClick={() => set.mode('diff')}
        title='View differences of changes from published version.'
        className={classNames(mode == 'diff' ? 'bg-orange-600 text-white' : 'bg-gray-600 hover:bg-orange-600 hover:text-white', "p-2 px-3 rounded-lg")}>
          <PlusIcon className="w-3" /><MinusIcon className="w-3" />
      </button>
    )}
    {modes.includes('revision') && (
      <button 
        role="button"
        onClick={() => set.mode('revision')}
        title="View the latest draft revision."
        className={classNames(mode == 'revision' ? 'bg-yellow-600 text-white' : 'bg-gray-600 hover:bg-yellow-600 hover:text-white', "p-2 rounded-lg")}>
          <WrenchScrewdriverIcon className="w-5" />
      </button>
    )}
  </div>
}

const ViewLinks = () => {
  const { preview } = usePreviewBar()
  const { resource, revision } = useContentFromRoute()
  const node = preview.mode == 'current' ? resource : revision ?? resource;
  const links = buildLinks(node)
  return <div className="flex gap-1">
    {links.map(link => <a className={classNames("p-2 whitespace-nowrap rounded-lg bg-navy-600/20", link.className)} key={link.href} href={link.href} title={link.title}>{link.text}</a>)}
  </div>  
}

export const PreviewTag = (props: Omit<PreviewBarContextProps["preview"], "links"> & {
  children: ReactNode
}) =>  {
  const context = usePreviewBar()
  const pathname = usePathname()
  const { resource } = useContentFromRoute()

  useEffect(() => {
    // If a mode is set and it not a valid mode, then redirect
    // the user to a path without the params.
    if (context.preview.mode !== undefined && !props.modes.includes(context.preview.mode)) {
      redirect(pathname)
    }

    if (context.preview.resource?.id != resource?.id) {
      context.set.resource(resource)
    }

    // If preview mode is not enabled, then do not trigger the modes.
    if (!context.enabled) {
      if (context.preview.mode) {
        redirect(pathname)
      }
      return
    }
    
    if (context.preview.mode === undefined && props.mode !== undefined) {
      context.set.mode(props.mode)
    }
    if (context.preview.state != props.state) {
      context.set.state(props.state)
    }
    if (JSON.stringify(context.preview.modes) != JSON.stringify(props.modes)) {
      context.set.modes(props.modes)
    }
  })

  if (!context.enabled) {
    return <>{props.children}</>
  }
  return <LoadingCover message='Loading preview...' isLoading={context.isLoading}>{props.children}</LoadingCover>
}

function buildLinks(node?: DrupalEntityWithJiraReference) {
  const links:LinkType[] = []

  if (!node) {
    return links
  }

  links.push({
    href: '/api/edit?nid=' + node.drupal_internal__nid,
    title: 'Go the page edit URL',
    text: <PencilSquareIcon className="w-5" />,
    className: 'hover:bg-orange-600 hover:text-white'
  })

  const jira_issue:string|false = node.field_jira_doc_issue?.id ?? node.field_jira_kb_issue?.id ?? false

  if (jira_issue && jira_issue != 'missing') {
    links.push({
      href: `https://acquia.atlassian.net/browse/${jira_issue}`,
      title: `Discuss on Jira ticket ${jira_issue}`,
      text: jira_issue,
      className: 'hover:bg-blue-600 hover:text-white'
    })
  }

  return links
}

