'use client'
import { usePathname } from "next/navigation";
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useId, useState } from "react";

export type SkipToContentContext = {
  setId?: Dispatch<SetStateAction<string | undefined>>
  id?: string
}

const SkipToContent = createContext<SkipToContentContext>({})

export const useSkipToContent = () => useContext(SkipToContent);

export const SkipToContentProvider = (props: {
  children: ReactNode
}) => {
  const [id, setId] = useState<string>()
  const pathname = usePathname()

  useEffect(() => {
    const body = document.body;
    
    // Temporarily make the body focusable
    body.setAttribute('tabindex', '-1');  
    body.focus();

    // Clean up: Remove the tabindex after focusing
    const removeTabIndex = () => {
      body.removeAttribute('tabindex');
    };

    // Use a timeout to ensure the tabindex is removed after focus
    const timeoutId = setTimeout(removeTabIndex, 0);

    // Cleanup function to clear timeout
    return () => clearTimeout(timeoutId);
  }, [pathname]);

  

  return <SkipToContent.Provider value={{setId, id}}>
    {id !== undefined && (
      <a href={`#${id}`} className="sr-only focus:not-sr-only">Skip to content</a>
    )}
    {props.children}
  </SkipToContent.Provider>
}

export const SkipToContentMarker = (props: {
  id: string
}) => {
  const { setId, id } = useSkipToContent()

  useEffect(() => {
    if (id !== props.id && setId !== undefined) {
      setId(props.id)
    }
  }, [props.id])

  return <a id={props.id} />
}