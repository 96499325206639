'use client'
import { Context, createContext, ReactNode, useCallback, useContext, useState } from "react";
import { SearchProvider } from "./SearchProvider";
import { ChatProvider } from "./ChatProvider";

export type DrawOptions = 'search' | 'chat'

export type SidebarDrawContext = {
  isOpen: boolean
  draw: DrawOptions | null,
  openDraw?: (value: DrawOptions) => void
  close?: () => void
  registerOnCloseCallback?: (name: string, callback: () => void) => void
}

const DrawContext = createContext<SidebarDrawContext>({
  isOpen: false,
  draw: null,
})

export const useSidebarDraw = () => useContext(DrawContext as Context<Required<SidebarDrawContext>>);

export const SidebarDrawProvider = ({children}: {
  children: ReactNode
}) => {
  const [open, setOpen] = useState<DrawOptions | null>(null);
  const [closeCallbacks, setCloseCallbacks] = useState<Record<string, () => void>>({})

  const registerOnCloseCallback = (name:string, callback: () => void) => {
    closeCallbacks[name] = callback;
    setCloseCallbacks(closeCallbacks)
  }

  function close() {
    setOpen(null)
    Object.values(closeCallbacks).forEach(callback => callback())
  }

  return <DrawContext.Provider value={{
    isOpen: open !== null,
    draw: open,
    openDraw: value => setOpen(value),
    close,
    registerOnCloseCallback,
  }}>
    <ChatProvider>
      <SearchProvider>
        {children}
      </SearchProvider>
    </ChatProvider>
  </DrawContext.Provider>
}