'use client'
import classNames from "classnames"
import { Session } from "next-auth"
import { useEffect, useState } from "react"
import { PencilSquareIcon } from "@heroicons/react/24/outline"
import { usePreviewBar } from "@/contexts/PreviewBarProvider"
import { isAcquian } from "@/lib/auth/authOptions"

export const UserNav =  ({session}: {
  session: Session | null
  className?: string
}) => {
  const [inIframe, setInIframe] = useState(true)
  const { preview: { resource }  } = usePreviewBar()

  useEffect(() =>  {
    setInIframe(typeof window !== "undefined" && window.self != window.top)
  }, [])

  if (session === null || inIframe) {
    return <></>
  }

  if (!isAcquian(session.user)) {
    return <></>
  }

  const btnClassName = 'border w-fit whitespace-nowrap border-white rounded-full p-3 transition-all duration-300'  

  return <>
      { resource && (
        <a href={`/api/edit?nid=${resource.drupal_internal__nid}`} title="Edit content" className={classNames(btnClassName, 'hover:border-orange-800 text-orange-800')}>
         <span className="sr-only">Edit</span>
         <PencilSquareIcon className="w-[24px]" />
       </a>
      )}
  </>
}