import access_code  from "@/access_code.json";
import { User, AuthOptions, Session } from "next-auth";
import CredentialsProvider from "next-auth/providers/credentials";
import GoogleProvider from "next-auth/providers/google"
import OktaProvider from "next-auth/providers/okta"
import { v4 as uuidv4 } from "uuid"

export function isAcquian(user: Session["user"] | undefined): boolean {
  return user?.email?.endsWith('@acquia.com') ?? false;
}

/**
 * Acquia Organisational Google SSO.
 */
const providers: AuthOptions["providers"] = [
  GoogleProvider({
    clientId: process.env.GOOGLE_CLIENT_ID as string,
    clientSecret: process.env.GOOGLE_CLIENT_SECRET as string,
    name: 'Employee SSO',
  })
]

/**
 * Acquia ID authentication.
 */
if (process.env.OKTA_CLIENT_ID != undefined) {
  providers.unshift(OktaProvider({
    clientId: process.env.OKTA_CLIENT_ID as string,
    clientSecret: process.env.OKTA_CLIENT_SECRET as string,
    issuer: process.env.OKTA_ISSUER as string,
    checks: ['pkce', 'state', 'nonce'],
    idToken: true,
    name: 'Acquia ID',
  }))
}

/**
 * PR environment access_code.
 */
if (access_code.length > 0) {
  providers.push(CredentialsProvider({
    name: "Access code",
    credentials: {
      access_code: { label: "Access code", type: "text" },
    },
    async authorize(credentials, req) {
      if (credentials === undefined) {
        return null;
      }

      if (access_code == credentials.access_code) {
        return {
          id: access_code,
          name: access_code,
          email: 'noreply+' + access_code + '@acquia.com',
        } as User
      }
      return null;
    }
  }))
}

declare module "next-auth" {
  interface Session {
    id: string;
  }
}

const options: AuthOptions = {
  // Configure one or more authentication providers
  providers,
  // This is also used in middleware.ts
  secret: 'lvra+qLUvXb/17xGYA+Cyh7CLbxuJUcpQCZHdJrCeH0=',
  // debug: true,
  cookies: {
    // Allow the sessionToken to be accessible through iframes (CMS preview)
    sessionToken: {
      name: `__Secure-next-auth.session-token`,
      options: {
        httpOnly: true,
        sameSite: 'none',
        path: '/',
        secure: true
      }
    },
  },
  theme: {
    brandColor: '#232C61',
  },
  callbacks: {
    async session({ session, user }) {
      // Generate a session ID and attach it to the session object
      session.id = uuidv4();
      return session;
    }
  }
}
export default options